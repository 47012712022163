










































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import View16 from '@carbon/icons-vue/es/view/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import isEmpty from 'lodash/isEmpty'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    GenericModalDelete,
    TrashCan16,
    View16,
    Edit16,
    TablePagination
  }
})
export default class EventStudiesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  forms = [] as any
  totalForms = 0
  filteredData = []
  deleteModalIsOpened = false
  selectedForm = {
    id: '',
    subject: ''
  }

  created () {
    this.fetch()
  }

  fetch (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })

    axios.get('medical/form', {
      params: {
        'page[number]': page,
        ...perPageSnake
      }
    })
      .then(response => {
        this.forms = this.filteredData = camelCaseKeys(response.data.data)
        this.totalForms = response.data.total
      })
  }

  postDeleteSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'O Questionário foi removido com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetch()
  }

  onPageChange (pagination: Pagination) {
    this.fetch(pagination.page, pagination.length)
  }

  get deleteUrl () {
    return isEmpty(this.selectedForm) ? '' : `/medical/form/${this.selectedForm.id}`
  }
}
