






import { Component, Vue } from 'vue-property-decorator'

import MedicalFormsTable from '@/partials/tables/Medical/Forms/index.vue'

@Component({
  components: {
    MedicalFormsTable
  }
})
export default class MedicalForms extends Vue {

}
